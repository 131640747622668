import {
	Box,
	Container,
	Divider,
	Grid,
	IconButton,
	Paper,
	SvgIcon,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TagIcon from "@mui/icons-material/Tag";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TopicIcon from "@mui/icons-material/Topic";
import { useNavigate, useParams } from "react-router-dom";
import { Stack } from "@mui/system";
import { getSingleArticle } from "../../../../services/article-service";
import Loader from "../../../../components/loader/Loader";
import { toastMessages } from "../../../../utils/toastMessage";
import RefreshLink from "../../../../components/refreshLink/RefreshLink";
import moment from "moment";

const ArticleDetails = () => {
	const navigate = useNavigate();
	const { article_id } = useParams();
	const [article, setArticle] = useState({});
	const [loading, setLoading] = useState(true);

	const fetchSingleArticles = async () => {
		try {
			const { data } = await getSingleArticle(article_id);
			setLoading(false);
			setArticle(data.article);
		} catch (error) {
			setLoading(false);
			// show error message
			if (error.code === "ERR_NETWORK") {
				toastMessages("Erreur de réseau", "error");
			} else {
				toastMessages(error?.response?.data?.message, "error");
			}
		}
	};

	useEffect(() => {
		fetchSingleArticles();
	}, []);

	return (
		<>
			<IconButton onClick={() => navigate(-1)} style={{ marginBottom: "1rem" }}>
				<SvgIcon>
					<ArrowBackIcon />
				</SvgIcon>
			</IconButton>

			<Container maxWidth="lg">
				<Typography variant="h4" mb="2rem">
					Détails de l'article
				</Typography>

				{loading && (
					<div className="center-loader">
						<Loader />{" "}
					</div>
				)}

				{Object.keys(article).length > 0 && (
					<Paper>
						<Box sx={{ py: "1.5rem", px: "1rem" }}>
							<Box
								sx={{
									width: { md: "65%" },
								}}
							>
								<Typography
									sx={{
										mb: "1.7rem",
										fontWeight: "bold",
									}}
									variant="h5"
								>
									{article?.title}
								</Typography>
							</Box>

							<Grid container spacing={3}>
								<Grid item xs={12} md={5}>
									{article?.media?.media_type === "picture" && (
										<Box
											sx={{
												background: "#e5e5e5",
												height: "300px",
											}}
										>
											<img
												style={{
													width: "100%",
													height: "100%",
													objectFit: "cover",
												}}
												alt="cover of an article"
												src={article?.media?.media_url}
											/>
										</Box>
									)}
									{article?.media?.media_type === "video" && (
										<Box>
											<iframe
												width="100%"
												height="450"
												src={article?.media?.media_url}
												title="YouTube video player"
												allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
												allowfullscreen
												style={{ background: "#e5e5e5" }}
											></iframe>
										</Box>
									)}
								</Grid>
								<Grid item xs={12} md={7}>
									<Stack
										pb="0.5rem"
										direction={{ xs: "column", sm: "row" }}
										spacing={3}
									>
										<Box>
											<Stack direction="row" spacing={1}>
												<SvgIcon style={{ color: "#829ab1" }}>
													<TopicIcon />{" "}
												</SvgIcon>
												<Typography color="primary" component="span">
													{article?.rubrique_title}
												</Typography>
											</Stack>
										</Box>
										<Box>
											<Stack direction="row" spacing={1}>
												<SvgIcon style={{ color: "#829ab1" }}>
													<TagIcon />{" "}
												</SvgIcon>
												<Typography color="primary" component="span">
													{article?.tag}
												</Typography>
											</Stack>
										</Box>
										<Box>
											<Stack direction="row" spacing={1}>
												<SvgIcon style={{ color: "#829ab1" }}>
													<CalendarMonthIcon />{" "}
												</SvgIcon>
												<Typography color="primary" component="span">
													{moment(article?.created_at).format("Do MMM YYYY")}{" "}
												</Typography>
											</Stack>
										</Box>
									</Stack>
									<Divider />
									<div
										style={{ marginTop: "0.5rem" }}
										dangerouslySetInnerHTML={{ __html: article?.description }}
									/>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				)}
			</Container>

			{Object.keys(article).length === 0 && loading === false && (
				<RefreshLink />
			)}
		</>
	);
};

export default ArticleDetails;
