import api, { apiAuth } from "../axios/api";

const getReplays = (query) => {
	const { limit = 20, page } = query;

	let url = "replays/list?";

	if (limit) {
		url = `${url}&limit=${limit}`;
	}

	if (page) {
		url = `${url}&page=${page}`;
	}
	return api.get(url).then((res) => res);
};

const getSingleReplay = (replay_id) => {
	return api.get(`replays/get/${replay_id}`).then((res) => res);
};

const deleteReplay = (replay_id) => {
	return apiAuth.delete(`replays/delete/${replay_id}`).then((res) => res);
};

const deleteProgram = (program_id) => {
	return apiAuth.delete(`programs/delete/${program_id}`).then((res) => res);
};

const createReplay = (data) => {
	return apiAuth.post('replays/create', data).then((res) => res);
};

const updateReplay = (replayID, data) => {
	return apiAuth.patch(`replays/update/${replayID}`, data).then((res) => res);
};

const updateProgram = (programID, data) => {
	return apiAuth.patch(`programs/update/${programID}`, data).then((res) => res);
};


const getPrograms = () => {
	return api.get(`programs/list`).then((res) => res);
};

const createProgram = (data) => {
	return apiAuth.post(`programs/create`, data).then((res) => res);
};

export {
	getReplays,
	getSingleReplay,
	getPrograms,
	createReplay,
	createProgram,
	deleteReplay,
	deleteProgram,
	updateReplay,
	updateProgram
};
