import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArticleIcon from "@mui/icons-material/Article";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import {
	Box,
	Button,
	Container,
	IconButton,
	Pagination,
	Paper,
	Stack,
	SvgIcon,
	TableContainer,
	Typography,
} from "@mui/material";
import {
	exportSubscribers,
	getSubscribers,
} from "../../../services/subscribers-services";
import Loader from "../../../components/loader/Loader";
import { toastMessages } from "../../../utils/toastMessage";
import moment from "moment";

const SubscribersList = () => {
	const navigate = useNavigate();
	const [page, setpage] = useState(1);
	const [loading, setLoading] = useState(false);
	const [loadingExp, setLoadingExp] = useState(false);
	const [subscribers, setSubscribers] = useState([]);
	const [pageCount, setPageCount] = useState(0);

	const handleChange = (e, value) => {
		setpage(value);
	};

	const exportSub = async () => {
		setLoadingExp(true);
		try {
			await exportSubscribers();
			setLoadingExp(false);
			toastMessages("Exporté avec succès", "success");
		} catch (error) {
			setLoadingExp(false);
			// show error message
			if (error.code === "ERR_NETWORK") {
				toastMessages("Erreur de réseau", "error");
			} else {
				toastMessages(error?.response?.data?.message, "error");
			}
		}
	};

	const fetch = async () => {
		setLoading(true);
		try {
			const { data } = await getSubscribers({ page });
			setLoading(false);
			setSubscribers(data?.data?.items);
			setPageCount(data?.data?.meta.totalPages);
		} catch (error) {
			setLoading(false);
			// show error message
			if (error.code === "ERR_NETWORK") {
				toastMessages("Erreur de réseau", "error");
			} else {
				toastMessages(error?.response?.data?.message, "error");
			}
		}
	};

	useEffect(() => {
		fetch();
	}, [page]);

	if (loading) {
		return (
			<div className="center-loader">
				<Loader />
			</div>
		);
	}

	return (
		<>
			<IconButton onClick={() => navigate(-1)}>
				<SvgIcon>
					<ArrowBackIcon />
				</SvgIcon>
			</IconButton>

			<Container maxWidth="lg">
				<Typography mb="1rem" variant="h4">
					Liste d'abonnés
				</Typography>

				{subscribers.length === 0 ? (
					<Typography variant="h6" gutterBottom color="primary">
						{" "}
						Aucun abonné pour le moment
					</Typography>
				) : (
					<>
						<Stack direction="row" justifyContent="flex-end" mb="1rem">
							<Button
								variant="contained"
								startIcon={!loadingExp && <ArticleIcon />}
								onClick={exportSub}
							>
								{loadingExp ? "En Cour..." : "exporter"}
							</Button>
						</Stack>

						<TableContainer component={Paper}>
							<Table sx={{ minWidth: 650 }} aria-label="simple table">
								<TableHead sx={{ bgcolor: "#294e70" }}>
									<TableRow>
										<TableCell sx={{ color: "white" }}>ID</TableCell>
										<TableCell sx={{ color: "white" }}>Email</TableCell>
										<TableCell sx={{ color: "white" }}>Date</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{subscribers?.map((row) => (
										<TableRow key={row?.id}>
											<TableCell component="th" scope="row">
												{row?.id}
											</TableCell>
											<TableCell>{row?.email}</TableCell>
											<TableCell>
												{moment(row?.created_at).format("Do MMM YYYY")}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>

						<Box mt="1.5rem">
							<Pagination
								count={pageCount}
								onChange={handleChange}
								color="primary"
							/>
						</Box>
					</>
				)}
			</Container>
		</>
	);
};

export default SubscribersList;
