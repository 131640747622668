import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Link, useParams } from "react-router-dom";

const ProgramList = ({ histories = [] }) => {
	const { replay_id } = useParams();
	return (
		<>
			{histories.map((item) => {
				return (
					<Box mb="1rem">
						<Link
							style={{ color: "black", textDecoration: "none" }}
							to={`/replays/details/${replay_id}/${item?.id}`}
						>
							<Box>
								<img
									src={item?.cover_image}
									style={{
										width: "100%",
										objectFit: "cover",
										background: "#e5e5e5",
									}}
									alt={"program covers"}
								/>
							</Box>

							<Typography
								sx={{
									mb: "1rem",
									fontWeight: "bold",
									fontSize: "0.9em",
								}}
								component="small"
							>
								{item?.name.substring(0, 40)}
							</Typography>
						</Link>
					</Box>
				);
			})}
		</>
	);
};

export default ProgramList;
