import {
	Backdrop,
	Button,
	CircularProgress,
	FormControl,
	FormHelperText,
	Grid,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import RichText from "../richText";
import SaveIcon from "@mui/icons-material/Save";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAppContext } from "../../context/appContext";
import { toastMessages } from "../../utils/toastMessage";
import { updateArticle, uploadImages } from "../../services/article-service";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";

const EditArticleForm = ({ article, loading, fetchSingleArticles }) => {
	const [cover_image, setCoverImage] = useState("");
	const [media_url, setMediaUrl] = useState("");
	const [loadingCoverImage, setLoadingCoverImage] = useState(false);
	const [loadingMedia, setLoadingMedia] = useState(false);
	const [loadingArticle, setLoadingArticle] = useState(false);

	const { article_id } = useParams();

	const SmallLoader = () => {
		return (
			<span
				className="small-loader"
				style={{
					marginBottom: "1rem",
					position: "absolute",
					right: "5px",
					top: "10px",
				}}
			></span>
		);
	};

	// tag and rubrique options
	const {
		tagsOptions,
		loadingTags,
		fetchTags,
		rubriquesOptions,
		loadingRubriques,
		fetchRubriques,
	} = useAppContext();

	// yup schema
	const ArticleSchema = Yup.object().shape({
		title: Yup.string().required().min(5),
		rubrique_id: Yup.string().required(),
		tag_id: Yup.string().required(),
		media_type: Yup.string().required(),
		description: Yup.string().required().min(10),
	});

	// upload images
	// clear values uponselection
	const clearValue = (event) => {
		event.target.value = null;
	};

	const upload = async (file, formData, loader, setter) => {
		if (file) {
			try {
				const { data } = await uploadImages(formData);
				setter(data.data.image);
				loader(false);
			} catch (error) {
				loader(false);
				// show error message
				if (error.code === "ERR_NETWORK") {
					toastMessages("Erreur de réseau", "error");
				} else {
					toastMessages(error?.response?.data?.message, "error");
				}
			}
		}
	};

	const uploadCoverImage = (event) => {
		const file = event.target.files[0];
		const formData = new FormData();
		formData.append("image", file);
		setLoadingCoverImage(true);
		upload(file, formData, setLoadingCoverImage, setCoverImage);
	};

	const uploadMediaUrl = (event) => {
		const file = event.target.files[0];
		const formData = new FormData();
		formData.append("image", file);
		setLoadingMedia(true);
		upload(file, formData, setLoadingMedia, setMediaUrl);
	};

	const handleSubmit = async (data, { resetForm }) => {
		const articlesInfo = {
			...data,
		};
		if (cover_image) {
			articlesInfo.cover_image = cover_image;
		}

		if (media_url) {
			articlesInfo.media_url = media_url;
		}

		setLoadingArticle(true);
		try {
			await updateArticle(articlesInfo, article_id);
			setLoadingArticle(false);
			toastMessages("Article édité avec succès", "success");
			resetForm({ values: "" });
			fetchSingleArticles();
		} catch (error) {
			setLoadingArticle(false);
			// show error message
			if (error.code === "ERR_NETWORK") {
				toastMessages("Erreur de réseau", "error");
			} else {
				toastMessages(error?.response?.data?.message, "error");
			}
		}
	};

	const tag = tagsOptions.find(({ name }) => name === article?.tag);
	const formikArticle = useFormik({
		enableReinitialize: true,
		initialValues: {
			title: article?.title || "",
			rubrique_id: article?.rubrique_id || "",
			tag_id: tag?.tag_id || "",
			media_type: article?.media?.media_type || "",
			description: article?.description || "",
		},
		validationSchema: ArticleSchema,
		onSubmit: handleSubmit,
	});

	useEffect(() => {
		fetchRubriques();
		fetchTags();
	}, []);

	return (
		<>
			{/* titre */}
			<Typography component="label">Titre</Typography>
			<TextField
				sx={{ width: { xs: "100%" }, mb: "1.5rem" }}
				error={!!formikArticle.errors.title && formikArticle.touched.title}
				helperText={
					formikArticle.errors.title &&
					formikArticle.touched.title &&
					formikArticle.errors.title
				}
				name="title"
				size="small"
				onBlur={formikArticle.handleBlur}
				onChange={formikArticle.handleChange}
				value={formikArticle.values.title}
			/>
			<Grid container spacing={4}>
				<Grid item xs={12} md={6}>
					{/* Rubrique */}
					<Box>
						<FormControl
							error={
								!!formikArticle.errors.rubrique_id &&
								formikArticle.touched.rubrique_id
							}
							sx={{ width: { xs: "100%" }, mb: "1.5rem" }}
						>
							<Typography component="label">Rubrique</Typography>
							<Select
								value={formikArticle.values.rubrique_id}
								onBlur={formikArticle.handleBlur}
								onChange={formikArticle.handleChange}
								name="rubrique_id"
								size="small"
							>
								{rubriquesOptions.map((item) => {
									return (
										<MenuItem key={item.rubrique_id} value={item.rubrique_id}>
											{item.rubrique_title}
										</MenuItem>
									);
								})}
							</Select>
							{formikArticle.errors.rubrique_id &&
								formikArticle.touched.rubrique_id && (
									<FormHelperText>
										{formikArticle.errors.rubrique_id}
									</FormHelperText>
								)}
							{loadingRubriques && <FormHelperText>Loading...</FormHelperText>}
						</FormControl>
					</Box>
				</Grid>

				<Grid item xs={12} md={6}>
					{/* tag */}
					<Box>
						<FormControl
							error={
								!!formikArticle.errors.tag_id && formikArticle.touched.tag_id
							}
							sx={{ width: { xs: "100%" }, mb: "1.5rem" }}
						>
							<Typography component="label">Tag</Typography>
							<Select
								value={formikArticle.values.tag_id}
								onChange={formikArticle.handleChange}
								onBlur={formikArticle.handleBlur}
								name="tag_id"
								size="small"
							>
								{tagsOptions.map((item) => {
									return (
										<MenuItem key={item.tag_id} value={item.tag_id}>
											{item.name}
										</MenuItem>
									);
								})}
							</Select>
							{formikArticle.errors.tag_id && formikArticle.touched.tag_id && (
								<FormHelperText>{formikArticle.errors.tag_id}</FormHelperText>
							)}
							{loadingTags && <FormHelperText>Loading...</FormHelperText>}
						</FormControl>
					</Box>
				</Grid>

				<Grid item xs={12} md={6}>
					{/* Cover image */}
					<Box>
						<Typography gutterBottom component="label">
							Cover Image
						</Typography>
					</Box>
					<Stack
						sx={{ position: "relative" }}
						direction="row"
						alignItems="center"
						spacing={1}
					>
						<Box sx={{ width: { xs: "100%" }, mb: "1.5rem" }}>
							<input
								style={{
									border: "1px solid #c4c4c4",
									borderRadius: "5px",
									padding: "0.5rem",
									width: "100%",
									cursor: "pointer",
								}}
								onClick={clearValue}
								onChange={uploadCoverImage}
								accept="image/*"
								type="file"
							/>
						</Box>
						{loadingCoverImage && <SmallLoader />}
					</Stack>
				</Grid>

				<Grid item xs={12} md={6}>
					{/* Description Cover image */}
					<Box>
						<Typography component="label">
							{" "}
							Description Cover Image (media type)
						</Typography>
					</Box>
					<FormControl
						error={
							!!formikArticle.errors.media_type &&
							formikArticle.touched.media_type
						}
						sx={{ width: { xs: "100%" }, mb: "1.5rem" }}
					>
						<Select
							value={formikArticle.values.media_type}
							onChange={formikArticle.handleChange}
							onBlur={formikArticle.handleBlur}
							name="media_type"
							size="small"
						>
							<MenuItem value={"picture"}>Image</MenuItem>
							<MenuItem value={"video"}>Video</MenuItem>
						</Select>
						{formikArticle.errors.media_type &&
							formikArticle.touched.media_type && (
								<FormHelperText>
									{formikArticle.errors.media_type}
								</FormHelperText>
							)}
					</FormControl>

					{/* image media type*/}
					{formikArticle.values.media_type === "picture" && (
						<Stack
							sx={{ position: "relative" }}
							direction="row"
							alignItems="center"
							spacing={1}
						>
							<Box sx={{ width: { xs: "100%" }, mb: "1.5rem" }}>
								<input
									style={{
										border: "1px solid #c4c4c4",
										borderRadius: "5px",
										padding: "0.5rem",
										width: "100%",
										cursor: "pointer",
									}}
									onClick={clearValue}
									onChange={uploadMediaUrl}
									accept="image/*"
									type="file"
								/>
							</Box>
							{loadingMedia && <SmallLoader />}
						</Stack>
					)}

					{/* video media type*/}
					{formikArticle.values.media_type === "video" && (
						<Box sx={{ width: { xs: "100%" }, mb: "1.5rem" }}>
							<TextField
								fullWidth
								onChange={(e) => setMediaUrl(e.target.value)}
								id="filled-basic"
								size="small"
								label="Video url"
								variant="outlined"
							/>
						</Box>
					)}
				</Grid>
			</Grid>

			{/* desCription */}
			<Box mt="1rem" mb="0.3rem">
				<Typography component="label"> Description</Typography>
			</Box>

			<RichText formikArticle={formikArticle} toolbarId="tool1" />

			{loadingArticle ? (
				<LoadingButton
					sx={{ mt: "2rem", mb: "0.5rem" }}
					loading
					loadingPosition="start"
					startIcon={<SaveIcon />}
					variant="outlined"
					size="large"
				></LoadingButton>
			) : (
				<Box mt="2rem" mb="0.5rem">
					<Button
						onClick={formikArticle.handleSubmit}
						size="large"
						variant="contained"
						disableElevation
					>
						Editer
					</Button>
				</Box>
			)}

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={loading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
};

export default EditArticleForm;
