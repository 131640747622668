import { Link, Typography } from "@mui/material";
import React from "react";

const RefreshLink = () => {
	return (
		<div
			style={{
				height: "100%",
				width: "100%",
				display: "flex",
				justifyContent: "center",
				marginTop: "15rem",
			}}
		>
			<Typography
				sx={{
					mb: "1.3rem",
				}}
				variant="h6"
				component="div"
			>
				Il y a eu un problème.{" "}
				<Link
					style={{ cursor: "pointer", color: " #87ADED" }}
					onClick={() => window.location.reload()}
				>
					rafraîchir
				</Link>{" "}
			</Typography>
		</div>
	);
};

export default RefreshLink;
