import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { AccountCircle } from "@mui/icons-material";
import { NavItems } from "../../../navigation/menuItems";
import { Menu, MenuItem, SvgIcon } from "@mui/material";
import logo from "../../../assets/images/foryou.jpg";
import { removeFromLocalStorage } from "../../../utils/localStorage";

const drawerWidth = 250;

function Layout() {
	const [mobileOpen, setMobileOpen] = useState(false);
	const navigate = useNavigate();

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const logOut = () => {
		removeFromLocalStorage("user");
		removeFromLocalStorage("token");
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const drawer = (
		<div>
			<Toolbar sx={{ bgcolor: "primary.main" }}>
				<img style={{ width: "48px" }} src={logo} alt="for you logo" />
			</Toolbar>
			<Divider />
			<List>
				{NavItems().map(({ label, icon, isActive, link }) => (
					<ListItem
						key={label}
						onClick={() => navigate(link)}
						className={`${isActive ? "active-link" : ""} item-link`}
						disablePadding
					>
						<ListItemButton>
							<ListItemIcon>
								<SvgIcon style={{ color: `${isActive ? "#102a43" : ""}` }}>
									{icon}
								</SvgIcon>
							</ListItemIcon>
							<ListItemText primary={label} fontWeight="700" />
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</div>
	);
	// sx={{}}
	return (
		<Box
			sx={{
				display: "flex",
				minHeight: "100vh",
				width: "100%",
				background: "#f0f4f8",
			}}
		>
			<CssBaseline />
			<AppBar
				position="fixed"
				elevation={0}
				sx={{
					width: { md: `calc(100% - ${drawerWidth}px)` },
					ml: { md: `${drawerWidth}px` },
				}}
			>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						sx={{ mr: 2, display: { md: "none" } }}
					>
						<MenuIcon />
					</IconButton>

					<Box sx={{ flexGrow: 1 }}>
						<Typography
							variant="h5"
							sx={{ display: { xs: "none", sm: "block" } }}
							component="div"
						>
							Admin Dashboard
						</Typography>
					</Box>

					<Box>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleClick}
							color="inherit"
						>
							<AccountCircle fontSize="large" />
						</IconButton>
					</Box>
					<Menu
						id="menu-appbar"
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						<MenuItem onClick={handleClose} component={Link} to="/profile">
							Profil
						</MenuItem>

						<MenuItem onClick={logOut} component={Link} to="/login">
							Se déconnecter
						</MenuItem>
					</Menu>
				</Toolbar>
			</AppBar>
			<Box
				component="nav"
				sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
				aria-label="mailbox folders"
			>
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Drawer
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { xs: "block", md: "none" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth,
						},
					}}
				>
					{drawer}
				</Drawer>
				<Drawer
					variant="permanent"
					sx={{
						display: { xs: "none", md: "block" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth,
						},
					}}
					open
				>
					{drawer}
				</Drawer>
			</Box>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					p: 3,
					width: { sm: `calc(100% - ${drawerWidth}px)` },
				}}
			>
				<Toolbar />
				<Outlet />
			</Box>
		</Box>
	);
}

export default Layout;
