import { Box, Divider, Paper, SvgIcon, Typography } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TopicIcon from "@mui/icons-material/Topic";
import { Stack } from "@mui/system";
import React from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useAppContext } from "../../context/appContext";

const ReplayDetails = () => {
	const { replay_sub_id } = useParams();
	const { singleReplay = {} } = useAppContext();
	const subReplay = singleReplay?.histories?.find(
		({ id }) => id === replay_sub_id
	);

	let data = replay_sub_id ? subReplay : singleReplay;

	return (
		<Paper>
			<Box>
				{data?.type === "SINGLE" || replay_sub_id ? (
					<iframe
						width="100%"
						height="450"
						src={data?.link}
						title="YouTube video player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowfullscreen
						style={{ background: "#e5e5e5" }}
					></iframe>
				) : (
					<Box
						sx={{
							background: "#e5e5e5",
							height: "450px",
						}}
					>
						<img
							style={{ width: "100%", height: "100%", objectFit: "cover" }}
							alt="cover of an article"
							src={data?.cover_image}
						/>
					</Box>
				)}
			</Box>

			<Box p="1rem">
				<Typography
					sx={{
						mb: "1rem",
						fontWeight: "bold",
					}}
					variant="h6"
				>
					{data?.name}
				</Typography>

				<Stack pb="0.4rem" direction={"row"} spacing={3}>
					<Stack direction="row" spacing={1}>
						<SvgIcon style={{ color: "#829ab1" }}>
							<TopicIcon />{" "}
						</SvgIcon>
						<Typography color="primary" component="span">
							{data?.type}
						</Typography>
					</Stack>

					<Stack direction="row" spacing={1}>
						<SvgIcon style={{ color: "#829ab1" }}>
							<CalendarMonthIcon />{" "}
						</SvgIcon>
						<Typography color="primary" component="span">
							{moment.unix(data?.created_at).format("Do MMM YYYY")}
						</Typography>
					</Stack>

					<Divider />
				</Stack>
				<div
					style={{ marginTop: "0.5rem" }}
					className="richText"
					dangerouslySetInnerHTML={{ __html: data?.description }}
				/>
			</Box>
		</Paper>
	);
};

export default ReplayDetails;
