import { Box, Button, Container, Pagination, Paper } from "@mui/material";
import ListArticles from "./listItems";
import AddIcon from "@mui/icons-material/Add";
import SearchForm from "./searchForm";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../../context/appContext";
import { useEffect, useState } from "react";
import Loader from "../../../../components/loader/Loader";
import { searchArticles } from "../../../../services/article-service";
import { toastMessages } from "../../../../utils/toastMessage";

const ArticleList = () => {
	const { fetchArticles, loadingArticles, articlePageCount, articles } =
		useAppContext();
	const [page, setpage] = useState(1);
	const [tag, setTag] = useState("");
	const [search, setSearch] = useState("");
	const [searchedArticles, setSearchedArticles] = useState([]);
	const [loadingSearch, setLoadingSearch] = useState(false);

	const data = search.length > 1 ? searchedArticles : articles;

	const [rubrique_id, setRubrique_id] = useState("");
	const navigate = useNavigate();

	const handleChange = (e, value) => {
		setpage(value);
	};

	const clearFilters = () => {
		setTag("");
		setRubrique_id("");
		setSearch("");
	};

	// search articles
	const searching = async () => {
		setLoadingSearch(true);
		try {
			const { data } = await searchArticles(search);
			setLoadingSearch(false);
			setSearchedArticles(data?.data?.results);
		} catch (error) {
			setLoadingSearch(false);
			// show error message
			if (error.code === "ERR_NETWORK") {
				toastMessages("Erreur de réseau", "error");
			} else {
				toastMessages(error?.response?.data?.message, "error");
			}
		}
	};

	useEffect(() => {
		if (search) {
			searching();
		} else {
			fetchArticles({ page, tag, rubrique_id });
		}
	}, [page, tag, rubrique_id, search]);

	return (
		<Container maxWidth="lg">
			<Box
				sx={{
					mb: "1.5rem",
					mt: "0.5rem",
				}}
			>
				<Button
					onClick={() => navigate("/articles/add")}
					size="medium"
					variant="contained"
					startIcon={<AddIcon />}
				>
					Ajouter un article
				</Button>
			</Box>

			<Paper elevation={3} sx={{ mb: "4rem" }}>
				<SearchForm
					tag={tag}
					setTag={setTag}
					rubrique_id={rubrique_id}
					setSearch={setSearch}
					search={search}
					setRubrique_id={setRubrique_id}
					clearFilters={clearFilters}
				/>
			</Paper>

			{loadingArticles || loadingSearch ? (
				<div className="center-loader">
					<Loader />{" "}
				</div>
			) : (
				<ListArticles
					page={page}
					rubrique_id={rubrique_id}
					tag={tag}
					articles={data}
				/>
			)}

			<Box
				sx={{
					position: "fixed",
					bottom: "0",
					display: "flex",
					alignItems: "center",
					background: "white",
					width: "100%",
					paddingBlock: "1rem",
				}}
			>
				<Pagination
					count={articlePageCount}
					onChange={handleChange}
					color="primary"
				/>
			</Box>
		</Container>
	);
};

export default ArticleList;
