import api, { apiAuth } from "../axios/api";

const getArticles = (query) => {
    const {limit=20,page,tag,rubrique_id} = query

    let url = 'articles/list?'
    
    if (tag) {
        url = `${url}&tag=${tag}`
    }

    if (rubrique_id) {
        url = `${url}&rubrique_id=${rubrique_id}`
    }

    if (limit) {
        url = `${url}&limit=${limit}`
    }

    if (page) {
        url = `${url}&page=${page}`
    } 
    return api.get(url).then((res) => res)
}

const searchArticles = (title) => {
    return api.get(`articles/search?query=${title}`).then((res) => res)
}

const createAticle = (data) => {
    return apiAuth.post('articles/create',data).then((res) => res)
}

const getSingleArticle = (article_id) => {
    return api.get(`articles/display/${article_id}`).then((res) => res)
}

const deleteArticle = (article_id) => {
    return apiAuth.delete(`articles/delete/${article_id}`).then((res) => res)
}

const updateArticle = (data, article_id) => {
    return apiAuth.patch(`articles/update/${article_id}`,data).then((res) => res)
}

const uploadImages = (data) => {
    return api.post('articles/upload-image',data).then((res) => res)
}

const getRubriques = () => {
    return api.get('rubriques/list').then((res) => res)
}

const getTags = () => {
    return api.get('tags/list').then((res) => res)
}

const createRubriques = (data) => {
    return apiAuth.post('rubriques/create',data).then((res) => res)
}

const createTags = (data) => {
    return apiAuth.post('tags/create',data).then((res) => res)
}


export  {
    getArticles,
    searchArticles,
    getSingleArticle,
    getRubriques,
    getTags,
    createRubriques,
    createTags,
    uploadImages,
    createAticle,
    deleteArticle,
    updateArticle
}