import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Grid,
	Paper,
	SvgIcon,
	Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
	deleteProgram,
	deleteReplay,
} from "../../../../../services/replay-service";
import { toastMessages } from "../../../../../utils/toastMessage";

const ReplayLists = ({ replays, fetchReplays, page }) => {
	const [openDelete, setOpenDelete] = useState(false);
	const [loading, setLoading] = useState(false);
	const [replayID, setReplayID] = useState("");
	const [replayType, setReplayType] = useState("");

	const navigate = useNavigate();
	const closeDeleteModal = (e, reason) => {
		if (reason && reason === "backdropClick") {
			return;
		}
		setOpenDelete(false);
	};

	const onDelete = async (replayID) => {
		if (replayType) {
			setLoading(true);
			try {
				const single =
					replayType === "SINGLE" && (await deleteReplay(replayID));
				const program =
					replayType === "PROGRAM" && (await deleteProgram(replayID));
				setLoading(false);
				toastMessages("Replay supprimée avec succès", "success");
				fetchReplays({ page });
				setOpenDelete(false);
			} catch (error) {
				setLoading(false);
				// show error message
				if (error.code === "ERR_NETWORK") {
					toastMessages("Network Error", "error");
				} else {
					toastMessages(error?.response?.data?.message, "error");
				}
			}
		}
	};

	let modal;

	if (openDelete) {
		modal = (
			<Dialog
				open={openDelete}
				onClose={closeDeleteModal}
				disableEscapeKeyDown
				aria-labelledby="draggable-dialog-title"
			>
				<DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
					Supprimer le Replay
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Êtes-vous sûr de vouloir supprimer cette rediffusion ?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setOpenDelete(false)}
						sx={{ fontWeight: "bold" }}
						color="error"
					>
						annuler
					</Button>
					<Button
						onClick={() => onDelete(replayID)}
						disabled={loading}
						sx={{ fontWeight: "bold" }}
						variant="contained"
					>
						Supprimer
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	return (
		<Box sx={{ width: "100%" }}>
			{replays.length >= 1 && (
				<Typography variant="h6" gutterBottom color="primary">
					{replays.length} Replay{replays.length === 1 ? "" : "s"}
				</Typography>
			)}
			{replays.length === 0 && (
				<Typography variant="h6" gutterBottom color="primary">
					{" "}
					Pas de Replay
				</Typography>
			)}

			{replays.length > 0 && (
				<Grid container spacing={4} pb="5rem">
					{replays.map((item) => {
						return (
							<Grid item xs={12} md={4} key={item.id}>
								<Paper elevation={2} sx={{ height: "100%" }}>
									<Box>
										<img
											src={item?.cover_image}
											style={{
												height: "200px",
												width: "100%",
												objectFit: "cover",
												background: "#e5e5e5",
											}}
											alt="replay cover"
										/>
									</Box>

									<Box py="0.3rem" px="1rem">
										<Typography variant="h6" component="div">
											{item.type}
										</Typography>
									</Box>

									<Divider />

									<Box p="1rem">
										<Box mb="1rem">
											<Stack direction="row" spacing={1}>
												<SvgIcon style={{ color: "#829ab1" }}>
													<CalendarMonthIcon />{" "}
												</SvgIcon>
												<Typography color="primary" component="span">
													{moment.unix(item?.created_at).format("Do MMM YYYY")}{" "}
												</Typography>
											</Stack>
										</Box>
										<Box>
											<Typography color="primary" variant="h6" component="div">
												{item.name.length > 100
													? `${item.name.substring(0, 100)}...`
													: item.name}
											</Typography>
										</Box>
									</Box>

									<Stack p="1rem" direction="row" spacing={2}>
										<Button
											onClick={() =>
												navigate(
													`/replays/details/${
														item?.type === "SINGLE"
															? item?.id
															: item?.histories[0].id
													}`
												)
											}
											size="small"
											sx={{ fontWeight: "bold" }}
											variant="outlined"
										>
											Details
										</Button>

										<Button
											onClick={() =>
												navigate(
													`/replays/edit/${
														item?.type === "SINGLE"
															? item?.id
															: item?.histories[0].id
													}`
												)
											}
											size="small"
											variant="outlined"
											color="success"
										>
											Editer
										</Button>

										<Button
											sx={{ fontWeight: "bold" }}
											onClick={() => {
												setReplayID(item?.id);
												setReplayType(item?.type);
												setOpenDelete(true);
											}}
											size="small"
											variant="outlined"
											color="error"
										>
											Supprimer
										</Button>
									</Stack>
								</Paper>
							</Grid>
						);
					})}
				</Grid>
			)}
			{modal}
		</Box>
	);
};

export default ReplayLists;
