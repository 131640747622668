import { getFromLocalStorage } from "./localStorage";

export function verifyUser() {

    const parseJwt = (token) => {
        try {
            return JSON.parse(window.atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    };

    const user = getFromLocalStorage('token');

    if (user) {
        const decodedJwt = parseJwt(user);

        if (decodedJwt.exp * 1000 < Date.now()) {
            return false;
        }
    }else {
        return false;
    }

    return true;

}