import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../../components/loader/Loader";
import ReplayDetails from "../../../../components/replayDetails";
import { useAppContext } from "../../../../context/appContext";

const Program = () => {
	const { loadingSingleReplay, fetchReplay, singleReplay } = useAppContext();

	const { replay_id } = useParams();

	useEffect(() => {
		if (Object.keys(singleReplay).length === 0) {
			fetchReplay(replay_id);
		}
	}, [replay_id]);

	if (loadingSingleReplay) {
		return (
			<div className="center-loader">
				<Loader />{" "}
			</div>
		);
	}
	return <ReplayDetails />;
};

export default Program;
