import HomeIcon from "@mui/icons-material/Home";
import { Add } from "@mui/icons-material";
import DescriptionIcon from "@mui/icons-material/Description";
import ReplayIcon from "@mui/icons-material/Replay";
import { useLocation } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import ArticleIcon from "@mui/icons-material/Article";

export const NavItems = () => {
	const location = useLocation();

	return [
		{
			label: "Statistique",
			icon: <HomeIcon />,
			isActive: location.pathname === "/" ? true : false,
			link: "/",
		},
		{
			label: "Ajouter un admin",
			icon: <Add />,
			isActive: location.pathname.indexOf("users") > 0,
			link: "/users",
		},
		{
			label: "Article",
			icon: <DescriptionIcon />,
			isActive: location.pathname.indexOf("articles") > 0,
			link: "/articles",
		},
		{
			label: "Rediffusion",
			icon: <ReplayIcon />,
			isActive: location.pathname.indexOf("replays") > 0,
			link: "/replays",
		},
		{
			label: "Liste d'abonnés",
			icon: <DoneIcon />,
			isActive: location.pathname.indexOf("subscribers/list") > 0,
			link: "/subscribers/list",
		},
		{
			label: "Fichier exportés",
			icon: <ArticleIcon />,
			isActive: location.pathname.indexOf("subscribers/exports") > 0,
			link: "/subscribers/exports",
		},
	];
};
