import { Button, Container, Pagination, Paper } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import SearchForm from "./searchForm";
import ReplayLists from "./listItems";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../../context/appContext";
import Loader from "../../../../components/loader/Loader";

const ReplayList = () => {
	const { fetchReplays, loadingReplay, replays, replayPageCount } =
		useAppContext();
	const [page, setpage] = useState(1);

	const handleChange = (e, value) => {
		setpage(value);
	};

	const navigate = useNavigate();

	useEffect(() => {
		fetchReplays({ page });
	}, [page]);

	return (
		<Container maxWidth="lg">
			<Box
				sx={{
					mb: "1.5rem",
					mt: "0.5rem",
				}}
			>
				<Button
					onClick={() => navigate("/replays/add")}
					size="medium"
					variant="contained"
					startIcon={<AddIcon />}
				>
					Ajouter un replay
				</Button>
			</Box>

			<Paper elevation={3} sx={{ mb: "4rem" }}>
				<SearchForm />
			</Paper>

			{loadingReplay ? (
				<div className="center-loader">
					<Loader />
				</div>
			) : (
				<ReplayLists
					replays={replays}
					fetchReplays={fetchReplays}
					page={page}
				/>
			)}

			<Box
				sx={{
					position: "fixed",
					bottom: "0",
					display: "flex",
					alignItems: "center",
					background: "white",
					width: "100%",
					paddingBlock: "1rem",
				}}
			>
				<Pagination
					count={replayPageCount}
					onChange={handleChange}
					color="primary"
				/>
			</Box>
		</Container>
	);
};

export default ReplayList;
