import {
	Backdrop,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Grid,
	Paper,
	SvgIcon,
	Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import TagIcon from "@mui/icons-material/Tag";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../../../context/appContext";
import { toastMessages } from "../../../../../utils/toastMessage";
import { deleteArticle } from "../../../../../services/article-service";
import moment from "moment";

const ListArticles = ({ page, tag, rubrique_id, articles }) => {
	const navigate = useNavigate();
	const { fetchArticles } = useAppContext();
	const [openDelete, setOpenDelete] = useState(false);
	const [articleId, setArticleId] = useState("");

	const [loading, setLoading] = useState(false);

	const onDelete = async (article_id) => {
		setLoading(true);
		try {
			await deleteArticle(article_id);
			setLoading(false);
			toastMessages("Article supprimé avec succès", "success");
			fetchArticles({ page, tag, rubrique_id });
			setOpenDelete(false);
		} catch (error) {
			setLoading(false);
			// show error message
			if (error.code === "ERR_NETWORK") {
				toastMessages("Erreur de réseau", "error");
			} else {
				toastMessages(error?.response?.data?.message, "error");
			}
		}
	};

	const closeDeleteModal = (e, reason) => {
		if (reason && reason === "backdropClick") {
			return;
		}
		setOpenDelete(false);
	};

	let modal;

	if (openDelete) {
		modal = (
			<Dialog
				open={openDelete}
				onClose={closeDeleteModal}
				disableEscapeKeyDown
				aria-labelledby="draggable-dialog-title"
			>
				<DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
					Supprimer l'article
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Êtes-vous sûr de vouloir supprimer cet article ?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setOpenDelete(false)}
						sx={{ fontWeight: "bold" }}
						color="error"
					>
						Annuler
					</Button>
					<Button
						sx={{ fontWeight: "bold" }}
						variant="contained"
						disabled={loading}
						onClick={() => onDelete(articleId)}
					>
						Supprimer
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	return (
		<Box sx={{ width: "100%" }}>
			{articles?.length >= 1 && (
				<Typography variant="h6" gutterBottom color="primary">
					{articles?.length} Article{articles?.length === 1 ? "" : "s"}
				</Typography>
			)}
			{articles?.length === 0 && (
				<Typography variant="h6" gutterBottom color="primary">
					{" "}
					Pas d'article
				</Typography>
			)}

			{articles?.length > 0 && (
				<Grid container spacing={4} pb="5rem">
					{articles?.map((item) => {
						return (
							<Grid item xs={12} md={4} key={item.id}>
								<Paper elevation={2} sx={{ height: "100%" }}>
									<Box>
										<img
											src={item?.cover_image}
											style={{
												height: "200px",
												width: "100%",
												objectFit: "cover",
												background: "#e5e5e5",
											}}
											alt="article cover"
										/>
									</Box>

									<Box p="1rem">
										<Typography variant="h5">{item.rubrique_title}</Typography>
										<Stack direction="row" spacing={1}>
											<SvgIcon style={{ color: "#829ab1" }}>
												<TagIcon />{" "}
											</SvgIcon>
											<Typography color="primary" component="span">
												{item?.tag}
											</Typography>
										</Stack>
									</Box>

									<Divider />

									<Box p="1rem">
										<Box mb="1rem">
											<Stack direction="row" spacing={1}>
												<SvgIcon style={{ color: "#829ab1" }}>
													<CalendarMonthIcon />{" "}
												</SvgIcon>
												<Typography color="primary" component="span">
													{moment(item?.created_at).format("Do MMM YYYY")}{" "}
												</Typography>
											</Stack>
										</Box>
										<Box>
											<Typography color="primary" variant="h6" component="div">
												{item.title.length > 100
													? `${item.title.substring(0, 100)}...`
													: item.title}
											</Typography>
										</Box>
									</Box>

									<Stack p="1rem" direction="row" spacing={2}>
										<Button
											onClick={() => navigate(`/articles/details/${item.id}`)}
											size="small"
											sx={{ fontWeight: "bold" }}
											variant="outlined"
										>
											Details
										</Button>
										<Button
											onClick={() => navigate(`/articles/edit/${item.id}`)}
											size="small"
											variant="outlined"
											color="success"
										>
											Editer
										</Button>

										<Button
											sx={{ fontWeight: "bold" }}
											onClick={() => {
												setOpenDelete(true);
												setArticleId(item?.id);
											}}
											size="small"
											variant="outlined"
											color="error"
										>
											Supprimer
										</Button>
									</Stack>
								</Paper>
							</Grid>
						);
					})}
				</Grid>
			)}
			{modal}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={loading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Box>
	);
};

export default ListArticles;
