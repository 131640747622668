import { toast } from "react-toastify"

export const toastMessages = (message, status) => {
    // Toast configuration 
    let toastConfig = {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    }

    let msg;

    if (status === 'error') {
        msg = toast.error(message, {
            ...toastConfig
        });
    } else if (status === 'success') {
        msg = toast.success(message, {
            ...toastConfig
        });
    } else {
        msg = toast.warning('Please set a message and status', {
            ...toastConfig
        });
    }

    return msg
}