import React from "react";

const FallBack = () => {
	return (
		<div className="fall-back">
			<div className="loading"></div>
		</div>
	);
};

export default FallBack;
