import { Box, FormControl, IconButton, MenuItem, Paper, Select, SvgIcon, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SingleForm from '../../../../components/replayForms/SingleForm';
import ProgramForm from '../../../../components/replayForms/ProgramForm';
import { Container } from '@mui/system';

const AddReplay = () => {
    const [type, setType] = useState('')

    const navigate = useNavigate()

    return (
        <>
            <IconButton onClick={() => navigate(-1)}>
                <SvgIcon>
                    <ArrowBackIcon />
                </SvgIcon>
            </IconButton>

            <Container maxWidth="md">
                <Box sx={{ pt: '2rem', px: '1rem', pb: '1rem' }}>
                    <Typography mb="1rem" variant='h4'> Ajouter une rediffusion</Typography>

                    <Box mb='2rem'>
                        <Typography component='label' >Choisir le type</Typography>
                        <FormControl fullWidth size="small" >
                            <Select
                                value={type}
                                variant="outlined"
                                onChange={(e) => setType(e.target.value)}
                                sx={{ width: '200px' }}
                            >
                                <MenuItem value={'SINGLE'}>Single</MenuItem>
                                <MenuItem value={'PROGRAM'}>Program</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    {type &&
                        <Paper sx={{ p: '1rem' }}>
                            {type === "SINGLE" && <SingleForm type={type} />}
                            {type === "PROGRAM" && <ProgramForm type={type} />}
                        </Paper>}
                </Box>
            </Container>
        </>
    )
}

export default AddReplay