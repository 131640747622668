import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import {
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Paper,
	Stack,
	SvgIcon,
	TextField,
	Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import SaveIcon from "@mui/icons-material/Save";
import ArticleForm from "../../../../components/articleForm";
import {
	createRubriques,
	createTags,
} from "../../../../services/article-service";
import { useAppContext } from "../../../../context/appContext";
import { LoadingButton } from "@mui/lab";
import { toastMessages } from "../../../../utils/toastMessage";

const AddArticle = () => {
	const [openTag, setOpenTag] = useState(false);
	const [openRubrique, setOpenRubrique] = useState(false);
	const { fetchTags, fetchRubriques } = useAppContext();
	const [loadingTag, setLoadingTag] = useState(false);
	const [loadingRubrique, setLoadingRubrique] = useState(false);

	const [rubriqueTitle, setRubriqueTitle] = useState("");
	const [tagTitle, setTagtitle] = useState("");

	const closeTagModal = (e, reason) => {
		if (reason && reason === "backdropClick") {
			return;
		}
		setOpenTag(false);
	};
	const closeRubriqueModal = (e, reason) => {
		if (reason && reason === "backdropClick") {
			return;
		}
		setOpenRubrique(false);
	};

	const navigate = useNavigate();

	const createTag = async () => {
		if (!tagTitle) {
			toastMessages("Veuillez saisir un titre", "error");
			return;
		}
		setLoadingTag(true);
		try {
			const { data } = await createTags({ name: tagTitle });
			setLoadingTag(false);
			fetchTags();
			closeTagModal();
			toastMessages(data?.message, "success");
		} catch (error) {
			setLoadingTag(false);
			if (error.code === "ERR_NETWORK") {
				toastMessages("Erreur de réseau", "error");
			} else {
				toastMessages(error?.response?.data?.message, "error");
			}
		}
	};

	const createRubrique = async () => {
		if (!rubriqueTitle) {
			toastMessages("Veuillez saisir un titre", "error");
			return;
		}

		setLoadingRubrique(true);
		try {
			const { data } = await createRubriques({ title: rubriqueTitle });
			setLoadingRubrique(false);
			fetchRubriques();
			closeRubriqueModal();
			toastMessages(data?.message, "success");
		} catch (error) {
			setLoadingRubrique(false);
			if (error.code === "ERR_NETWORK") {
				toastMessages("Erreur de réseau", "error");
			} else {
				toastMessages(error?.response?.data?.message, "error");
			}
		}
	};

	let addTagModal;

	if (openTag) {
		addTagModal = (
			<Dialog
				open={openTag}
				disableEscapeKeyDown
				fullWidth
				maxWidth="xs"
				onClose={closeTagModal}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle sx={{ fontWeight: "bold" }}>{"Créer un tag"}</DialogTitle>
				<DialogContent>
					<Typography component="label"> Titre</Typography>
					<Box mb="1rem">
						<TextField
							size="small"
							name="title"
							onChange={(e) => setTagtitle(e.target.value)}
							fullWidth
						/>
					</Box>

					<Box>
						{" "}
						<label>Image (Optionnelle)</label>
					</Box>
					<Box mb="0.6rem">
						<input
							style={{
								border: "1px solid #c4c4c4",
								borderRadius: "5px",
								padding: "0.5rem",
								width: "100%",
								cursor: "pointer",
							}}
							accept="image/*"
							type="file"
						/>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button
						disabled={loadingTag}
						onClick={() => setOpenTag(false)}
						sx={{ fontWeight: "bold" }}
						color="error"
					>
						Close
					</Button>
					{loadingTag ? (
						<LoadingButton
							loading
							loadingPosition="start"
							startIcon={<SaveIcon />}
							variant="outlined"
						></LoadingButton>
					) : (
						<Button
							onClick={createTag}
							sx={{ fontWeight: "bold" }}
							variant="contained"
						>
							Créer
						</Button>
					)}
				</DialogActions>
			</Dialog>
		);
	}

	let addRuriqueModal;

	if (openRubrique) {
		addRuriqueModal = (
			<Dialog
				open={openRubrique}
				disableEscapeKeyDown
				fullWidth
				maxWidth="xs"
				onClose={closeRubriqueModal}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle sx={{ fontWeight: "bold" }}>
					{"Créer une Rubrique"}
				</DialogTitle>
				<DialogContent>
					<Typography component="label"> Titre</Typography>
					<Box mb="1rem">
						<TextField
							size="small"
							name="title"
							onChange={(e) => setRubriqueTitle(e.target.value)}
							fullWidth
						/>
					</Box>

					<Box>
						{" "}
						<label>Image (Optionnelle)</label>
					</Box>
					<Box mb="0.6rem">
						<input
							style={{
								border: "1px solid #c4c4c4",
								borderRadius: "5px",
								padding: "0.5rem",
								width: "100%",
								cursor: "pointer",
							}}
							accept="image/*"
							type="file"
						/>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button
						disabled={loadingRubrique}
						onClick={() => setOpenRubrique(false)}
						color="error"
						sx={{ fontWeight: "bold" }}
					>
						Close
					</Button>

					{loadingRubrique ? (
						<LoadingButton
							loading
							loadingPosition="start"
							startIcon={<SaveIcon />}
							variant="outlined"
						></LoadingButton>
					) : (
						<Button
							onClick={createRubrique}
							sx={{ fontWeight: "bold" }}
							variant="contained"
						>
							Créer
						</Button>
					)}
				</DialogActions>
			</Dialog>
		);
	}

	return (
		<>
			<IconButton onClick={() => navigate(-1)} style={{ marginBottom: "1rem" }}>
				<SvgIcon>
					<ArrowBackIcon />
				</SvgIcon>
			</IconButton>

			<Container maxWidth="md">
				<Stack
					direction="row"
					justifyContent="space-between"
					spacing={3}
					mb="2rem"
				>
					<Typography variant="h4"> Ajouter un article</Typography>

					<Stack direction="row" spacing={2}>
						<Box>
							<Button
								onClick={() => setOpenTag(true)}
								size="small"
								variant="contained"
								startIcon={<AddIcon />}
							>
								Ajouter un Tag
							</Button>
						</Box>

						<Box>
							<Button
								onClick={() => setOpenRubrique(true)}
								size="small"
								variant="contained"
								startIcon={<AddIcon />}
							>
								Ajouter une Rubrique
							</Button>
						</Box>
					</Stack>
				</Stack>

				<Paper>
					<Box sx={{ pt: "2rem", px: "1rem", pb: "1rem" }}>
						<ArticleForm />
					</Box>
				</Paper>
			</Container>
			{addTagModal}
			{addRuriqueModal}
		</>
	);
};

export default AddArticle;
