import {
	Backdrop,
	Button,
	CircularProgress,
	Dialog,
	IconButton,
	Paper,
	Stack,
	SvgIcon,
	Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Container } from "@mui/system";
import Single from "../../../../components/EditReplayForm/Single";
import Program from "../../../../components/EditReplayForm/Program";
import { toastMessages } from "../../../../utils/toastMessage";
import { getSingleReplay } from "../../../../services/replay-service";

const EditReplayPage = () => {
	const navigate = useNavigate();
	const { replay_id } = useParams();
	const [replay, setReplay] = useState({});
	const [loading, setLoading] = useState(false);
	const [type, setType] = useState("");
	const [sublink, setSublink] = useState("");
	const [open, setOpen] = useState(false);

	const openModal = () => {
		setOpen(true);
	};

	const closeModal = () => {
		setOpen(false);
	};

	const fetchReplay = async () => {
		setLoading(true);
		try {
			const { data } = await getSingleReplay(replay_id);
			setLoading(false);
			setReplay(data.data);
		} catch (error) {
			setLoading(false);
			// show error message
			if (error.code === "ERR_NETWORK") {
				toastMessages("Erreur de réseau", "error");
			} else {
				toastMessages(error?.response?.data?.message, "error");
			}
		}
	};

	useEffect(() => {
		fetchReplay();
	}, [replay_id]);

	return (
		<>
			<IconButton onClick={() => navigate(-1)} style={{ marginBottom: "1rem" }}>
				<SvgIcon>
					<ArrowBackIcon />
				</SvgIcon>
			</IconButton>

			<Container maxWidth="md">
				<Typography variant="h4" mb="1.5rem">
					Editer la rediffusion
				</Typography>

				<Stack direction="row" justifyContent="flex-end" gap={2} mb="0.8rem">
					<Button
						variant="outlined"
						onClick={() => {
							setType("coverImage");
							openModal();
						}}
					>
						Voir cover image
					</Button>
					{replay?.type === "SINGLE" && (
						<Button
							mr="1rem"
							variant="contained"
							onClick={() => {
								setType("video");
								openModal();
							}}
						>
							voir la video
						</Button>
					)}
				</Stack>
				<Paper>
					<Box p="1rem">
						{replay?.type === "SINGLE" && (
							<Single
								data={replay}
								replay_id={replay_id}
								fetchReplay={fetchReplay}
							/>
						)}
						{replay?.type === "PROGRAM" && (
							<Program
								data={replay}
								setType={setType}
								openModal={openModal}
								fetchReplay={fetchReplay}
								setSublink={setSublink}
							/>
						)}
					</Box>
				</Paper>
			</Container>

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={loading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<Dialog
				open={open}
				fullWidth
				maxWidth="sm"
				onClose={closeModal}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				{type === "video" && (
					<iframe
						width="100%"
						height="300"
						src={sublink ? sublink : replay?.link}
						title="YouTube video player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowfullscreen
						style={{ background: "#e5e5e5" }}
					></iframe>
				)}

				{type === "coverImage" && (
					<img
						src={replay?.cover_image}
						alt="cover"
						height="100%"
						width="100%"
					/>
				)}
			</Dialog>
		</>
	);
};

export default EditReplayPage;
