import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { uploadImages } from "../../services/article-service";
import { toastMessages } from "../../utils/toastMessage";
import RichText from "../richText";
import SaveIcon from "@mui/icons-material/Save";
import { createReplay } from "../../services/replay-service";
import { LoadingButton } from "@mui/lab";

const SingleForm = ({ type, program_id }) => {
	const [loadingCoverImage, setLoadingCoverImage] = useState(false);
	const [loading, setLoading] = useState(false);
	const [cover_image, setCoverImage] = useState("");

	const SmallLoader = () => {
		return (
			<span
				className="small-loader"
				style={{
					marginBottom: "1rem",
					position: "absolute",
					right: "5px",
					top: "10px",
				}}
			></span>
		);
	};

	// yup schema
	const ArticleSchema = Yup.object().shape({
		name: Yup.string().required().min(5),
		link: Yup.string().required(),
		description: Yup.string().required().min(10),
	});

	// Upload Cover image
	// clear values uponselection
	const clearValue = (event) => {
		event.target.value = null;
	};
	const upload = async (event) => {
		const file = event.target.files[0];
		const formData = new FormData();
		formData.append("image", file);
		setLoadingCoverImage(true);

		try {
			const { data } = await uploadImages(formData);
			setCoverImage(data.data.image);
			setLoadingCoverImage(false);
		} catch (error) {
			setLoadingCoverImage(false);
			// show error message
			if (error.code === "ERR_NETWORK") {
				toastMessages("Erreur de réseau", "error");
			} else {
				toastMessages(error?.response?.data?.message, "error");
			}
		}
	};

	// Upload all data
	const handleSubmit = async (data, { resetForm }) => {
		const replayInfo = {
			...data,
			cover_image,
			type,
		};

		if (type === "PROGRAM") {
			replayInfo.program_id = program_id;
		}

		if (!cover_image) {
			toastMessages("Veuillez fournir un cover image", "error");
			return;
		}

		setLoading(true);
		try {
			await createReplay(replayInfo);
			setLoading(false);
			toastMessages("Replay créé avec succès", "success");
			resetForm({ values: "" });
		} catch (error) {
			setLoading(false);
			// show error message
			if (error.code === "ERR_NETWORK") {
				toastMessages("Erreur de réseau", "error");
			} else {
				toastMessages(error?.response?.data?.message, "error");
			}
		}
	};

	const formikArticle = useFormik({
		initialValues: {
			name: "",
			link: "",
			description: "",
		},
		validationSchema: ArticleSchema,
		onSubmit: handleSubmit,
	});

	return (
		<>
			<Grid item xs={12} md={4}>
				{/* titre */}
				<Typography component="label">Titre</Typography>
				<TextField
					sx={{ width: { xs: "100%" }, mb: "1.5rem" }}
					error={!!formikArticle.errors.name && formikArticle.touched.name}
					helperText={
						formikArticle.errors.name &&
						formikArticle.touched.name &&
						formikArticle.errors.name
					}
					name="name"
					size="small"
					onBlur={formikArticle.handleBlur}
					onChange={formikArticle.handleChange}
					value={formikArticle.values.name}
				/>
			</Grid>

			<Grid container spacing={4}>
				<Grid item xs={12} md={6}>
					{/* Video url */}
					<Typography component="label">Video Url</Typography>
					<TextField
						sx={{ width: { xs: "100%" }, mb: "1.5rem" }}
						error={!!formikArticle.errors.link && formikArticle.touched.link}
						helperText={
							formikArticle.errors.link &&
							formikArticle.touched.link &&
							formikArticle.errors.link
						}
						name="link"
						size="small"
						onBlur={formikArticle.handleBlur}
						onChange={formikArticle.handleChange}
						value={formikArticle.values.link}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					{/* Cover image */}
					<Box>
						<Typography gutterBottom component="label">
							Cover Image
						</Typography>
					</Box>
					<Stack
						style={{ position: "relative" }}
						direction="row"
						alignItems="center"
						spacing={1}
					>
						<Box sx={{ width: { xs: "100%" }, mb: "1.5rem" }}>
							<input
								onChange={upload}
								onClick={clearValue}
								style={{
									border: "1px solid #c4c4c4",
									borderRadius: "5px",
									padding: "0.5rem",
									width: "100%",
									cursor: "pointer",
								}}
								accept="image/*"
								type="file"
							/>
						</Box>
						{loadingCoverImage && <SmallLoader />}
					</Stack>
				</Grid>
			</Grid>

			{/* description */}
			<Box mb="0.2rem">
				<Typography gutterBottom component="label">
					{" "}
					Description
				</Typography>
			</Box>

			<RichText formikArticle={formikArticle} toolbarId="tool5" />

			<Box mt="2rem" mb="0.5rem">
				{loading ? (
					<LoadingButton
						loading
						loadingPosition="start"
						startIcon={<SaveIcon />}
						variant="outlined"
						size="large"
					></LoadingButton>
				) : (
					<Button
						size="large"
						onClick={formikArticle.handleSubmit}
						variant="contained"
						disableElevation
					>
						Ajouter
					</Button>
				)}
			</Box>
		</>
	);
};

export default SingleForm;
