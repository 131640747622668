import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Grid,
	Paper,
	TextField,
	Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import RichText from "../richText";
import { toastMessages } from "../../utils/toastMessage";
import { uploadImages } from "../../services/article-service";
import { LoadingButton } from "@mui/lab";
import { deleteReplay, updateProgram } from "../../services/replay-service";
import Single from "./Single";

const Program = ({ data, fetchReplay, setType, openModal, setSublink }) => {
	const programID = data?.id;
	const image = data?.cover_image;

	const [openDelete, setOpenDelete] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [loadingDelete, setLoadingDelete] = useState(false);
	const [id, setId] = useState("");
	const [loadingCoverImage, setLoadingCoverImage] = useState(false);
	const [loading, setLoading] = useState(false);
	const [cover_image, setCoverImage] = useState("");

	const replay = data?.histories?.find((item) => id === item?.id);

	const closeDeleteModal = (e, reason) => {
		if (reason && reason === "backdropClick") {
			return;
		}
		setOpenDelete(false);
	};

	const closeEditModal = (e, reason) => {
		if (reason && reason === "backdropClick") {
			return;
		}
		setOpenEdit(false);
	};

	const SmallLoader = () => {
		return (
			<span
				className="small-loader"
				style={{
					marginBottom: "1rem",
					position: "absolute",
					right: "5px",
					top: "10px",
				}}
			></span>
		);
	};

	// yup schema
	const ArticleSchema = Yup.object().shape({
		title: Yup.string().required().min(3),
		description: Yup.string().required().min(10),
	});

	// Upload Cover image
	// clear values uponselection
	const clearValue = (event) => {
		event.target.value = null;
	};
	const upload = async (event) => {
		const file = event.target.files[0];
		const formData = new FormData();
		formData.append("image", file);
		setLoadingCoverImage(true);

		try {
			const { data } = await uploadImages(formData);
			setCoverImage(data.data.image);
			setLoadingCoverImage(false);
		} catch (error) {
			setLoadingCoverImage(false);
			// show error message
			if (error.code === "ERR_NETWORK") {
				toastMessages("Erreur de réseau", "error");
			} else {
				toastMessages(error?.response?.data?.message, "error");
			}
		}
	};

	// Upload all data
	const handleSubmit = async (data, { resetForm }) => {
		const programInfo = {
			...data,
			cover_image: image,
		};

		if (cover_image) {
			programInfo.cover_image = cover_image;
		}

		setLoading(true);
		try {
			await updateProgram(programID, programInfo);
			setLoading(false);
			fetchReplay();
			toastMessages("Programme édité avec succès", "success");
			resetForm({ values: "" });
		} catch (error) {
			setLoading(false);
			// show error message
			if (error.code === "ERR_NETWORK") {
				toastMessages("Erreur de réseau", "error");
			} else {
				toastMessages(error?.response?.data?.message, "error");
			}
		}
	};

	const formikArticle = useFormik({
		enableReinitialize: true,
		initialValues: {
			title: data?.name || "",
			description: data?.description || "",
		},
		validationSchema: ArticleSchema,
		onSubmit: handleSubmit,
	});

	const onDelete = async () => {
		setLoadingDelete(true);
		try {
			await deleteReplay(id);
			setLoadingDelete(false);
			toastMessages("Replay supprimée avec succès", "success");
			fetchReplay();
			setOpenDelete(false);
		} catch (error) {
			setLoadingDelete(false);
			// show error message
			if (error.code === "ERR_NETWORK") {
				toastMessages("Erreur de réseau", "error");
			} else {
				toastMessages(error?.response?.data?.message, "error");
			}
		}
	};

	let modal;

	if (openDelete) {
		modal = (
			<Dialog
				open={openDelete}
				onClose={closeDeleteModal}
				disableEscapeKeyDown
				aria-labelledby="draggable-dialog-title"
			>
				<DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
					Supprimer la rediffusion
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Êtes-vous sûr de vouloir supprimer cette rediffusion ?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setOpenDelete(false)}
						sx={{ fontWeight: "bold" }}
						color="error"
					>
						Annuler
					</Button>
					<Button
						onClick={onDelete}
						disabled={loadingDelete}
						sx={{ fontWeight: "bold" }}
						variant="contained"
					>
						Supprimer
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	let editModal;

	if (openEdit) {
		editModal = (
			<Dialog
				open={openEdit}
				onClose={closeEditModal}
				disableEscapeKeyDown
				maxWidth="md"
				aria-labelledby="draggable-dialog-title"
			>
				<DialogContent>
					<Single
						data={replay}
						replay_id={id}
						fetchReplay={fetchReplay}
						closeEditModal={closeEditModal}
					/>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={closeEditModal} color="error">
						fermer
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
	return (
		<>
			<Typography mb="1rem" variant="h6">
				Programme
			</Typography>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					{/* titre */}
					<Typography component="label">Nom</Typography>
					<TextField
						sx={{ width: { xs: "100%" } }}
						error={!!formikArticle.errors.title && formikArticle.touched.title}
						helperText={
							formikArticle.errors.title &&
							formikArticle.touched.title &&
							formikArticle.errors.title
						}
						name="title"
						size="small"
						onBlur={formikArticle.handleBlur}
						onChange={formikArticle.handleChange}
						value={formikArticle.values.title}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					{/* Cover image */}
					<Box>
						<Typography gutterBottom component="label">
							Cover Image
						</Typography>
					</Box>
					<Stack
						sx={{ position: "relative" }}
						direction="row"
						alignItems="center"
						spacing={1}
					>
						<Box sx={{ width: { xs: "100%" }, mb: "1rem" }}>
							<input
								onChange={upload}
								onClick={clearValue}
								style={{
									border: "1px solid #c4c4c4",
									borderRadius: "5px",
									padding: "0.5rem",
									width: "100%",
									cursor: "pointer",
								}}
								accept="image/*"
								type="file"
							/>
						</Box>
						{loadingCoverImage && <SmallLoader />}
					</Stack>
				</Grid>
			</Grid>

			{/* description */}
			<Box mb="0.2rem">
				<Typography gutterBottom component="label">
					{" "}
					Description
				</Typography>
			</Box>
			<RichText
				formikArticle={formikArticle}
				height="250px"
				toolbarId="tool7"
			/>

			<Box my="1rem">
				{loading ? (
					<LoadingButton
						loading
						loadingPosition="start"
						startIcon={<SaveIcon />}
						variant="outlined"
						size="large"
					></LoadingButton>
				) : (
					<Button
						onClick={formikArticle.handleSubmit}
						size="large"
						variant="contained"
						disableElevation
					>
						Editer le programme
					</Button>
				)}
			</Box>

			<Divider />
			{/* Sous-program */}
			<Typography mt="1rem" mb="2rem" variant="h6">
				Sous-programmes
			</Typography>

			{data?.histories.map((item) => (
				<Box mb="3rem" key={item?.id}>
					<Paper elevation={3}>
						<Grid container spacing={2}>
							<Grid item xs={4}>
								<img
									height="100%"
									width="100%"
									alt="cover"
									style={{ objectFit: "cover" }}
									src={item?.cover_image}
								/>
							</Grid>
							<Grid item xs={8} px="0.5rem">
								<Typography variant="h6">{item?.name}</Typography>

								<Stack direction="row" spacing={2} mt="1rem">
									<Button
										variant="outlined"
										size="small"
										onClick={() => {
											openModal();
											setType("video");
											setSublink(item?.link);
										}}
									>
										voir la video
									</Button>
									<Button
										variant="outlined"
										onClick={() => {
											setOpenEdit(true);
											setId(item?.id);
										}}
										color="success"
										size="small"
									>
										Editer
									</Button>
									<Button
										variant="outlined"
										onClick={() => {
											setOpenDelete(true);
											setId(item?.id);
										}}
										color="error"
										size="small"
									>
										Supprimer
									</Button>
								</Stack>
							</Grid>
						</Grid>
					</Paper>
				</Box>
			))}
			{modal}
			{editModal}
		</>
	);
};

export default Program;
