import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../../components/loader/Loader";
import RefreshLink from "../../../../components/refreshLink/RefreshLink";
import ReplayDetails from "../../../../components/replayDetails";
import ProgramList from "../../../../components/replayDetails/programList";
import { useAppContext } from "../../../../context/appContext";

const Details = () => {
	const { singleReplay, loadingSingleReplay, fetchReplay } = useAppContext();

	const { replay_id } = useParams();

	useEffect(() => {
		fetchReplay(replay_id);
	}, [replay_id]);

	if (loadingSingleReplay) {
		return (
			<div className="center-loader">
				<Loader />{" "}
			</div>
		);
	}
	return (
		<>
			{Object.keys(singleReplay).length > 0 && (
				<Stack direction={{ md: "row" }} gap={2}>
					<Box flex={singleReplay?.histories ? 0.8 : 1}>
						<ReplayDetails />
					</Box>

					{singleReplay?.histories && (
						<Box flex="0.2">
							<ProgramList histories={singleReplay?.histories} />
						</Box>
					)}
				</Stack>
			)}

			{Object.keys(singleReplay).length === 0 &&
				loadingSingleReplay === false && <RefreshLink />}
		</>
	);
};

export default Details;
