import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import FallBack from "./components/fallBack";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import AddArticle from "./pages/dashboard/article/addArticle";
import ArticleList from "./pages/dashboard/article/articleList";
import ArticleDetails from "./pages/dashboard/article/details";
import EditArticle from "./pages/dashboard/article/editArticle";
import Layout from "./pages/dashboard/layout";
import AddReplay from "./pages/dashboard/replay/addReplay";
import ReplayDetailsOutlet from "./pages/dashboard/replay/details";
import Details from "./pages/dashboard/replay/details/details";
import Program from "./pages/dashboard/replay/details/program";
import EditReplayPage from "./pages/dashboard/replay/edit";
import ReplayList from "./pages/dashboard/replay/replayList";
import ProtectedRoutes from "./pages/protectedRoutes";
import SubscribersList from "./pages/dashboard/subscribers/subscribersList";
import ExportedSubscribers from "./pages/dashboard/subscribers/exportedSubscribers";

const LoginPage = lazy(() => import("./pages/login"));
const HomePage = lazy(() => import("./pages/dashboard/home"));
const AddUserPage = lazy(() => import("./pages/dashboard/user"));
const ProfilePage = lazy(() => import("./pages/dashboard/profile"));
const ArticlePage = lazy(() => import("./pages/dashboard/article"));
const ReplayPage = lazy(() => import("./pages/dashboard/replay"));
const NotFoundPage = lazy(() => import("./pages/notFound"));

function App() {
	return (
		<Suspense fallback={<FallBack />}>
			<ScrollToTop>
				<Routes>
					{/* protected routes */}
					<Route
						path="/"
						element={
							<ProtectedRoutes>
								<Layout />
							</ProtectedRoutes>
						}
					>
						{/* Home */}
						<Route index element={<HomePage />} />

						<Route path="/users" element={<AddUserPage />} />

						<Route path="/profile" element={<ProfilePage />} />

						{/* Article */}
						<Route path="/articles" element={<ArticlePage />}>
							<Route index element={<ArticleList />} />
							<Route path="add" element={<AddArticle />} />
							<Route path="edit/:article_id" element={<EditArticle />} />
							<Route path="details/:article_id" element={<ArticleDetails />} />
						</Route>

						{/* Replay */}
						<Route path="/replays" element={<ReplayPage />}>
							<Route index element={<ReplayList />} />
							<Route path="add" element={<AddReplay />} />
							<Route
								path="details/:replay_id"
								element={<ReplayDetailsOutlet />}
							>
								<Route index element={<Details />} />
								<Route path=":replay_sub_id" element={<Program />} />
							</Route>
							<Route path="edit/:replay_id" element={<EditReplayPage />} />
						</Route>

						{/* Subscribers */}
						<Route path="subscribers/list" element={<SubscribersList />} />
						<Route
							path="subscribers/exports"
							element={<ExportedSubscribers />}
						/>
					</Route>

					{/* public routes */}
					<Route path="/login" element={<LoginPage />} />
					<Route path="*" element={<NotFoundPage />} />
				</Routes>
			</ScrollToTop>
		</Suspense>
	);
}

export default App;
