import React, { useContext, useState } from "react";
import {
	getArticles,
	getRubriques,
	getTags,
} from "../services/article-service";
import { getReplays, getSingleReplay } from "../services/replay-service";
import { getFromLocalStorage } from "../utils/localStorage";
import { toastMessages } from "../utils/toastMessage";

const AppContext = React.createContext();

export const AppProvider = ({ children }) => {
	// user info
	const [user] = useState(JSON.parse(getFromLocalStorage("user")) || null);

	//articles
	const [articles, setArticles] = useState([]);
	const [loadingArticles, setLoadingArticles] = useState(false);
	const [articlePageCount, setArticlePageCount] = useState(0);

	const fetchArticles = async (query = {}) => {
		setLoadingArticles(true);
		try {
			const { data } = await getArticles(query);
			setLoadingArticles(false);
			setArticles(data.articles);
			setArticlePageCount(data.meta.totalPages);
		} catch (error) {
			setLoadingArticles(false);
			// show error message
			if (error.code === "ERR_NETWORK") {
				toastMessages("Erreur de réseau", "error");
			} else {
				toastMessages(error?.response?.data?.message, "error");
			}
		}
	};

	//replays
	const [replays, setReplay] = useState([]);
	const [loadingReplay, setLoadingReplay] = useState(false);
	const [replayPageCount, setReplayPageCount] = useState(0);

	const fetchReplays = async (query = {}) => {
		setLoadingReplay(true);
		try {
			const { data } = await getReplays(query);
			setLoadingReplay(false);
			setReplay(data.data.replays);
			setReplayPageCount(data.data.meta.totalPages);
		} catch (error) {
			setLoadingReplay(false);
			// show error message
			if (error.code === "ERR_NETWORK") {
				toastMessages("Erreur de réseau", "error");
			} else {
				toastMessages(error?.response?.data?.message, "error");
			}
		}
	};

	// replay
	const [singleReplay, setSingleReplay] = useState({});
	const [loadingSingleReplay, setLoadingSingleReplay] = useState(false);

	const fetchReplay = async (replay_id) => {
		setLoadingSingleReplay(true);
		try {
			const { data } = await getSingleReplay(replay_id);
			setLoadingSingleReplay(false);
			setSingleReplay(data.data);
		} catch (error) {
			setLoadingSingleReplay(false);
			// show error message
			if (error.code === "ERR_NETWORK") {
				toastMessages("Erreur de réseau", "error");
			} else {
				toastMessages(error?.response?.data?.message, "error");
			}
		}
	};

	//tags
	const [tagsOptions, setTags] = useState([]);
	const [loadingTags, setLoadingTags] = useState(false);

	const fetchTags = async () => {
		setLoadingTags(true);
		try {
			const { data } = await getTags();
			setTags(data.data);
			setLoadingTags(false);
			console.log(data);
		} catch (error) {
			setLoadingTags(false);
			console.log(error);
		}
	};

	//rubrique
	const [rubriquesOptions, setRubriques] = useState([]);
	const [loadingRubriques, setLoadingRubriques] = useState(false);

	const fetchRubriques = async () => {
		setLoadingRubriques(true);
		try {
			const { data } = await getRubriques();
			setRubriques(data.data);
			setLoadingRubriques(false);
		} catch (error) {
			setLoadingRubriques(false);
			console.log(error);
		}
	};

	return (
		<AppContext.Provider
			value={{
				user,
				articles,
				loadingArticles,
				fetchArticles,
				articlePageCount,
				tagsOptions,
				loadingTags,
				fetchTags,
				rubriquesOptions,
				loadingRubriques,
				fetchRubriques,
				replays,
				loadingReplay,
				fetchReplays,
				replayPageCount,
				singleReplay,
				loadingSingleReplay,
				fetchReplay,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

export const useAppContext = () => {
	return useContext(AppContext);
};
