import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
	Button,
	Container,
	Dialog,
	IconButton,
	Paper,
	Stack,
	SvgIcon,
	Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/system";
import EditArticleForm from "../../../../components/EditArticleForm";
import { getSingleArticle } from "../../../../services/article-service";
import { toastMessages } from "../../../../utils/toastMessage";

const EditArticle = () => {
	const navigate = useNavigate();
	const [article, setArticle] = useState({});
	const [loading, setLoading] = useState(true);
	const { article_id } = useParams();
	const [open, setOpen] = useState(false);
	const [type, setType] = useState("");

	const openModal = () => {
		setOpen(true);
	};

	const closeModal = () => {
		setOpen(false);
	};

	const fetchSingleArticles = async () => {
		try {
			const { data } = await getSingleArticle(article_id);
			setLoading(false);
			setArticle(data.article);
		} catch (error) {
			setLoading(false);
			// show error message
			if (error.code === "ERR_NETWORK") {
				toastMessages("Erreur de réseau", "error");
			} else {
				toastMessages(error?.response?.data?.message, "error");
			}
		}
	};

	useEffect(() => {
		fetchSingleArticles();
	}, []);

	return (
		<>
			<IconButton onClick={() => navigate(-1)} style={{ marginBottom: "1rem" }}>
				<SvgIcon>
					<ArrowBackIcon />
				</SvgIcon>
			</IconButton>

			<Container maxWidth="md">
				<Stack
					direction="row"
					justifyContent="space-between"
					mb="2rem"
					spacing={2}
				>
					<Typography variant="h4"> Editer l'article</Typography>

					<Stack direction="row" spacing={2}>
						<Box>
							<Button
								size="small"
								variant="outlined"
								onClick={() => {
									openModal();
									setType("coverImage");
								}}
							>
								{" "}
								Voir cover image{" "}
							</Button>
						</Box>

						<Box>
							<Button
								size="small"
								variant="contained"
								onClick={() => {
									openModal();
									setType("descriptionImage");
								}}
							>
								{" "}
								Voir description cover image
							</Button>
						</Box>
					</Stack>
				</Stack>

				<Paper>
					<Box sx={{ pt: "2rem", px: "1rem", pb: "1rem" }}>
						<EditArticleForm
							article={article}
							loading={loading}
							fetchSingleArticles={fetchSingleArticles}
						/>
					</Box>
				</Paper>
			</Container>
			<Dialog
				open={open}
				fullWidth
				maxWidth="sm"
				onClose={closeModal}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				{type === "descriptionImage" && (
					<>
						{article?.media?.media_type === "picture" && (
							<img
								src={article?.media?.media_url}
								alt="cover"
								height="100%"
								width="100%"
							/>
						)}
						{article?.media?.media_type === "video" && (
							<iframe
								width="100%"
								height="300"
								src={article?.media?.media_url}
								title="YouTube video player"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowfullscreen
								style={{ background: "#e5e5e5" }}
							></iframe>
						)}
					</>
				)}

				{type === "coverImage" && (
					<img
						src={article.cover_image}
						alt="cover"
						height="100%"
						width="100%"
					/>
				)}
			</Dialog>
		</>
	);
};

export default EditArticle;
