const getFromLocalStorage = (name) => {
    return localStorage.getItem(name)
}

const setToLocalStorage = (name,value) => {
    return localStorage.setItem(name,value)
}

const removeFromLocalStorage = (name) => {
    return localStorage.removeItem(name)
}

export {getFromLocalStorage,setToLocalStorage,removeFromLocalStorage}