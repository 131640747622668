import axios from "axios";
import { getFromLocalStorage } from "../utils/localStorage";
const base_url = process.env.REACT_APP_BASE_URL

const api = axios.create({
    baseURL: `${base_url}`
})

 export const apiAuth = axios.create({
    baseURL: `${base_url}`
})

apiAuth.interceptors.request.use(
    (request) => {
        const token = JSON.parse(getFromLocalStorage('token'))
        request.headers.Authorization = `Bearer ${token}`
        return request
    },
    (error) => {
        return Promise.reject(error)
    })

export default api