import api, { apiAuth } from "../axios/api";

export const getSubscribers = (query) => {
	const { limit = 20, page } = query;

	let url = "subscribers/list?";

	if (limit) {
		url = `${url}&limit=${limit}`;
	}

	if (page) {
		url = `${url}&page=${page}`;
	}
	return apiAuth.get(url).then((res) => res);
};

export const exportSubscribers = () => {
	return apiAuth.get("subscribers/export").then((res) => res);
};

export const getExportedFiles = () => {
	return apiAuth.get("subscribers/exportations/csv").then((res) => res);
};

export const downloadFiles = (fileName) => {
	return apiAuth
		.post(`subscribers/exportations/csv/download/${fileName}`, {
			responseType: "blob",
		})
		.then((res) => res);
};
