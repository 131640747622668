import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import {
	Button,
	Container,
	IconButton,
	Paper,
	SvgIcon,
	Typography,
} from "@mui/material";
import { toastMessages } from "../../../utils/toastMessage";
import {
	downloadFiles,
	getExportedFiles,
} from "../../../services/subscribers-services";
import Loader from "../../../components/loader/Loader";

const ExportedSubscribers = () => {
	const [loading, setLoading] = useState(false);
	const [loadingDownload, setLoadingDownload] = useState(false);
	const [files, setFiles] = useState([]);
	const [fileId, setFileId] = useState("");
	const navigate = useNavigate();

	const fetch = async () => {
		setLoading(true);
		try {
			const { data } = await getExportedFiles();
			setLoading(false);
			setFiles(data?.data);
		} catch (error) {
			setLoading(false);
			// show error message
			if (error.code === "ERR_NETWORK") {
				toastMessages("Erreur de réseau", "error");
			} else {
				toastMessages(error?.response?.data?.message, "error");
			}
		}
	};

	const downloadFile = async (fileName) => {
		setFileId(fileName);
		setLoadingDownload(true);
		try {
			const { data } = await downloadFiles(fileName);
			setLoadingDownload(false);
			let fileURL = window.URL.createObjectURL(new Blob([data]));
			let fileLink = document.createElement("a");

			fileLink.href = fileURL;
			fileLink.setAttribute("download", fileName);
			document.body.appendChild(fileLink);
			fileLink.click();
		} catch (error) {
			setLoadingDownload(false);
			// show error message
			if (error.code === "ERR_NETWORK") {
				toastMessages("Erreur de réseau", "error");
			} else {
				toastMessages(error?.response?.data?.message, "error");
			}
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	if (loading) {
		return (
			<div className="center-loader">
				<Loader />
			</div>
		);
	}

	return (
		<>
			<IconButton onClick={() => navigate(-1)}>
				<SvgIcon>
					<ArrowBackIcon />
				</SvgIcon>
			</IconButton>

			<Container maxWidth="lg">
				<Typography mb="2.5rem" variant="h4">
					Fichier exportés
				</Typography>

				{files.length === 0 ? (
					<Typography variant="h6" gutterBottom color="primary">
						{" "}
						Aucun fichier exporté pour le moment
					</Typography>
				) : (
					<>
						<Paper>
							<Table sx={{ minWidth: 650 }} aria-label="simple table">
								<TableHead sx={{ bgcolor: "#294e70" }}>
									<TableRow>
										<TableCell sx={{ color: "white" }}>
											Nom de fichier
										</TableCell>
										<TableCell sx={{ color: "white" }}>Action</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{files.map((file) => (
										<TableRow key={file}>
											<TableCell component="th" scope="row">
												{file}
											</TableCell>
											<TableCell>
												<Button
													sx={{ fontWeight: "bold" }}
													onClick={() => downloadFile(file)}
												>
													{loadingDownload && fileId === file
														? "En Cour..."
														: "télécharger"}
												</Button>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Paper>
					</>
				)}
			</Container>
		</>
	);
};

export default ExportedSubscribers;
