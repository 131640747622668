import { Navigate } from "react-router-dom";
import { getFromLocalStorage } from "../../utils/localStorage";
import { verifyUser } from "../../utils/verifyUser";

const ProtectedRoutes = ({ children }) => {
	const user = JSON.parse(getFromLocalStorage("user"));

	return verifyUser() && user?.role?.name === "ADMIN" ? (
		children
	) : (
		<Navigate to="/login" />
	);
};

export default ProtectedRoutes;
