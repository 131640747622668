import { IconButton, SvgIcon, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Container } from "@mui/system";

const ReplayDetailsOutlet = () => {
	const navigate = useNavigate();

	return (
		<div>
			<IconButton onClick={() => navigate(-1)} style={{ marginBottom: "1rem" }}>
				<SvgIcon>
					<ArrowBackIcon />
				</SvgIcon>
			</IconButton>

			<Container maxWidth="lg">
				<Typography mb="1.3rem" variant="h4">
					{" "}
					Détail d'une rediffusion
				</Typography>
				<Outlet />
			</Container>
		</div>
	);
};

export default ReplayDetailsOutlet;
