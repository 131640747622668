import React from "react";
import {
	Box,
	Button,
	FormControl,
	Grid,
	MenuItem,
	OutlinedInput,
	Select,
	Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const SearchForm = () => {
	return (
		<>
			<Box
				sx={{
					pt: "1.5rem",
					pb: "1rem",
					px: "2rem",
				}}
			>
				<Typography
					sx={{
						mb: "1.5rem",
					}}
					variant="h5"
					component="div"
				>
					Recherche
				</Typography>

				<Box component="form">
					<Grid container spacing={4}>
						<Grid item xs={12} md={4}>
							<Typography variant="h6" component="label">
								Rechercher
							</Typography>

							<FormControl fullWidth variant="outlined" size="small">
								<OutlinedInput
									id="outlined-adornment-weight"
									type="text"
									placeholder="Search by name"
									startAdornment={<SearchIcon position="start" />}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={4}>
							<Typography variant="h6" component="label">
								{" "}
								Type
							</Typography>
							<FormControl fullWidth size="small">
								<Select
								//    value={rubrique_id}
								//    onChange={(e) => setRubrique_id(e.target.value)}
								>
									<MenuItem value={"All"}>Tout</MenuItem>
									<MenuItem value={"SINGLE"}>Single</MenuItem>
									<MenuItem value={"PROGRAM"}>Program</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={4}>
							<Button
								sx={{ color: "#882329", mt: { md: "2rem" } }}
								variant="contained"
								fullWidth
								color="clear"
							>
								Effacer les filtres
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</>
	);
};

export default SearchForm;
