import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppProvider } from './context/appContext';
import { createTheme, ThemeProvider } from '@mui/material';


const theme = createTheme({
  palette: {
    primary: {
      main: '#102a43'
    },
    secondary: {
      main:'#102a43'
    },
    clear:{
      main:'#f8d7da'
    }
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <React.StrictMode>
        <AppProvider>
          <App />
        </AppProvider>
        <ToastContainer limit={2} />
      </React.StrictMode>
    </BrowserRouter>
  </ThemeProvider>
);
