import { FormHelperText } from "@mui/material";
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./styles.css";
// import { v4 as uuidv4 } from 'uuid';
import QuillToolbar, { formats, modules } from "./ToolBar";

const RichText = ({
	formikArticle,
	height = "500px",
	toolbarId = "default",
}) => {
	// generating random id's caused issues while displaying text editor, so to use this component provide unique toolbarId(text)
	// let uniqueId = uuidv4();
	// uniqueId = `#q${uniqueId}`
	return (
		<div>
			<QuillToolbar toolbarId={toolbarId} />
			<ReactQuill
				theme="snow"
				value={formikArticle?.values?.description || ""}
				// onBlur={formikArticle?.handleBlur}
				onChange={(choice) =>
					formikArticle?.setFieldValue("description", choice)
				}
				modules={modules(`#${toolbarId}`)}
				formats={formats}
				style={{ height: height }}
			/>
			{formikArticle?.errors?.description &&
				formikArticle?.touched?.description && (
					<FormHelperText style={{ color: "#d74251" }}>
						{formikArticle?.errors?.description}
					</FormHelperText>
				)}
		</div>
	);
};

export default React.memo(RichText);
