import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import RichText from "../richText";
import SingleForm from "./SingleForm";
import { toastMessages } from "../../utils/toastMessage";
import { createProgram, getPrograms } from "../../services/replay-service";
import { uploadImages } from "../../services/article-service";
import { LoadingButton } from "@mui/lab";

const ProgramForm = ({ type }) => {
	const [openProgram, setOpenProgram] = useState(false);
	const [loadingPrograms, setLoadingProgramList] = useState(false);
	const [programs, setPrograms] = useState([]);
	const [checked, setChecked] = useState("");
	const [loadingCoverImage, setLoadingCoverImage] = useState(false);
	const [loading, setLoading] = useState(false);
	const [cover_image, setCoverImage] = useState("");

	const closeProgramModal = (e, reason) => {
		if (reason && reason === "backdropClick") {
			return;
		}
		setOpenProgram(false);
	};

	const SmallLoader = () => {
		return (
			<span
				className="small-loader"
				style={{
					marginBottom: "1rem",
					position: "absolute",
					right: "5px",
					top: "10px",
				}}
			></span>
		);
	};

	const getProgramsList = async () => {
		setLoadingProgramList(true);
		try {
			const { data } = await getPrograms();
			setLoadingProgramList(false);
			setPrograms(data.data.programs);
		} catch (error) {
			setLoadingProgramList(false);
			// show error message
			if (error.code === "ERR_NETWORK") {
				toastMessages("Erreur de réseau", "error");
			} else {
				toastMessages(error?.response?.data?.message, "error");
			}
		}
	};

	// yup schema
	const ArticleSchema = Yup.object().shape({
		title: Yup.string().required().min(3),
		description: Yup.string().required().min(10),
	});

	// Upload Cover image
	// clear values uponselection
	const clearValue = (event) => {
		event.target.value = null;
	};
	const upload = async (event) => {
		const file = event.target.files[0];
		const formData = new FormData();
		formData.append("image", file);
		setLoadingCoverImage(true);

		try {
			const { data } = await uploadImages(formData);
			setCoverImage(data.data.image);
			setLoadingCoverImage(false);
		} catch (error) {
			setLoadingCoverImage(false);
			// show error message
			if (error.code === "ERR_NETWORK") {
				toastMessages("Erreur de réseau", "error");
			} else {
				toastMessages(error?.response?.data?.message, "error");
			}
		}
	};

	// Upload all data
	const handleSubmit = async (data, { resetForm }) => {
		const programInfo = {
			...data,
			cover_image,
		};

		if (!cover_image) {
			toastMessages("Veuillez fournir un cover image", "error");
			return;
		}

		setLoading(true);
		try {
			await createProgram(programInfo);
			setLoading(false);
			toastMessages("Programme créé avec succès", "success");
			resetForm({ values: "" });
			setOpenProgram(false);
			getProgramsList();
		} catch (error) {
			setLoading(false);
			// show error message
			if (error.code === "ERR_NETWORK") {
				toastMessages("Erreur de réseau", "error");
			} else {
				toastMessages(error?.response?.data?.message, "error");
			}
		}
	};

	const formikArticle = useFormik({
		initialValues: {
			title: "",
			description: "",
		},
		validationSchema: ArticleSchema,
		onSubmit: handleSubmit,
	});
	let addProgramModal;

	if (openProgram) {
		addProgramModal = (
			<Dialog
				open={openProgram}
				disableEscapeKeyDown
				fullWidth
				maxWidth="md"
				onClose={closeProgramModal}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle textAlign="center" mb="1rem" fontSize="1.5rem">
					Créer un programme
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={4}>
						<Grid item xs={12} md={6}>
							{/* titre */}
							<Typography component="label">Nom</Typography>
							<TextField
								sx={{ width: { xs: "100%" } }}
								error={
									!!formikArticle.errors.title && formikArticle.touched.title
								}
								helperText={
									formikArticle.errors.title &&
									formikArticle.touched.title &&
									formikArticle.errors.title
								}
								name="title"
								size="small"
								onBlur={formikArticle.handleBlur}
								onChange={formikArticle.handleChange}
								value={formikArticle.values.title}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							{/* Cover image */}
							<Box>
								<Typography gutterBottom component="label">
									Cover Image
								</Typography>
							</Box>
							<Stack
								sx={{ position: "relative" }}
								direction="row"
								alignItems="center"
								spacing={1}
							>
								<Box sx={{ width: { xs: "100%" }, mb: "1rem" }}>
									<input
										onChange={upload}
										onClick={clearValue}
										style={{
											border: "1px solid #c4c4c4",
											borderRadius: "5px",
											padding: "0.5rem",
											width: "100%",
											cursor: "pointer",
										}}
										accept="image/*"
										type="file"
									/>
								</Box>
								{loadingCoverImage && <SmallLoader />}
							</Stack>
						</Grid>
					</Grid>

					{/* description */}
					<Box mb="0.2rem">
						<Typography gutterBottom component="label">
							{" "}
							Description
						</Typography>
					</Box>
					<RichText
						formikArticle={formikArticle}
						height="250px"
						toolbarId="tool4"
					/>
				</DialogContent>

				<DialogActions>
					<Button
						size="large"
						sx={{ fontWeight: "bold" }}
						color="error"
						onClick={closeProgramModal}
						disableElevation
					>
						Annuler
					</Button>

					{loading ? (
						<LoadingButton
							loading
							loadingPosition="start"
							startIcon={<SaveIcon />}
							variant="outlined"
							size="large"
						></LoadingButton>
					) : (
						<Button
							size="large"
							onClick={formikArticle.handleSubmit}
							variant="contained"
							disableElevation
						>
							Ajouter
						</Button>
					)}
				</DialogActions>
			</Dialog>
		);
	}

	useEffect(() => {
		getProgramsList();
	}, []);

	return (
		<>
			<Box mb="1rem">
				<Button
					onClick={() => setOpenProgram(true)}
					size="small"
					variant="contained"
					startIcon={<AddIcon />}
				>
					Ajouter un programme
				</Button>
			</Box>
			<Typography variant="h5" ml="5rem">
				ou
			</Typography>
			<Typography variant="h5" mb="1rem" mt="0.5rem">
				Choisir un programme
			</Typography>

			{/* List of programs */}
			<Grid
				container
				sx={{ overflowY: "auto", height: "auto", maxHeight: "250px" }}
			>
				{programs.length === 0 && loadingPrograms === false && (
					<Box>Vous n'avez aucun programme</Box>
				)}

				{loadingPrograms ? (
					<Box>Loading...</Box>
				) : (
					<>
						{programs.map((item) => {
							return (
								<Grid item xs={12} md={6}>
									<RadioGroup
										value={checked}
										onChange={(e) => setChecked(e.target.value)}
									>
										<FormControlLabel
											value={item?.id}
											control={<Radio />}
											label={`${item?.title.substring(0, 40)}...`}
										/>
									</RadioGroup>
								</Grid>
							);
						})}
					</>
				)}
			</Grid>

			{checked && (
				<Box>
					<Typography variant="h5" textAlign="center" my="1rem">
						Ajouter un sous-programme
					</Typography>
					<SingleForm type={type} program_id={checked} />
				</Box>
			)}
			{addProgramModal}
		</>
	);
};

export default ProgramForm;
