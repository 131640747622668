import React, { useEffect } from "react";
import {
	Box,
	Button,
	FormControl,
	FormHelperText,
	Grid,
	MenuItem,
	OutlinedInput,
	Select,
	Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useAppContext } from "../../../../../context/appContext";

const SearchForm = ({
	tag,
	setTag,
	rubrique_id,
	setRubrique_id,
	clearFilters,
	setSearch,
	search,
}) => {
	const {
		tagsOptions,
		loadingTags,
		fetchTags,
		rubriquesOptions,
		loadingRubriques,
		fetchRubriques,
	} = useAppContext();

	useEffect(() => {
		fetchRubriques();
		fetchTags();
	}, []);

	return (
		<>
			<Box
				sx={{
					pt: "1.5rem",
					pb: "1rem",
					px: "2rem",
				}}
			>
				<Typography
					sx={{
						mb: "1.5rem",
					}}
					variant="h5"
					component="div"
				>
					{" "}
					Recherche
				</Typography>

				<Box component="form">
					<Grid container spacing={4}>
						<Grid item xs={12} md={4}>
							<Typography variant="h6" component="label">
								{" "}
								Rechercher{" "}
							</Typography>

							<FormControl fullWidth variant="outlined" size="small">
								<OutlinedInput
									id="outlined-adornment-weight"
									type="text"
									placeholder="Recherche par titre"
									value={search}
									onChange={(e) => setSearch(e.target.value)}
									startAdornment={<SearchIcon position="start" />}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={4}>
							<Typography variant="h6" component="label">
								{" "}
								Rubrique
							</Typography>
							<FormControl fullWidth size="small">
								<Select
									value={rubrique_id}
									onChange={(e) => setRubrique_id(e.target.value)}
								>
									{rubriquesOptions.map((item) => {
										return (
											<MenuItem key={item.rubrique_id} value={item.rubrique_id}>
												{item.rubrique_title}
											</MenuItem>
										);
									})}
								</Select>
								{loadingRubriques && (
									<FormHelperText>Loading...</FormHelperText>
								)}
							</FormControl>
						</Grid>
						<Grid item xs={12} md={4}>
							<Typography variant="h6" component="label">
								{" "}
								Tag
							</Typography>
							<FormControl fullWidth size="small">
								<Select value={tag} onChange={(e) => setTag(e.target.value)}>
									{tagsOptions.map((item) => {
										return (
											<MenuItem key={item.tag_id} value={item.name}>
												{item.name}
											</MenuItem>
										);
									})}
								</Select>
								{loadingTags && <FormHelperText>Loading...</FormHelperText>}
							</FormControl>
						</Grid>
						<Grid item xs={12} md={4}>
							<Button
								onClick={clearFilters}
								sx={{ color: "#882329" }}
								variant="contained"
								fullWidth
								color="clear"
							>
								Effacer les filtres
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</>
	);
};

export default SearchForm;
